import { useCallback, useEffect, useState, } from "react";
import { useDispatch } from "react-redux";
import { useColorMode } from "theme-ui";
import { setInnerLoaderFalse, setInnerLoaderTrue } from "../Redux/loaderSlice";

export const useThemeColor = () => {
    const dispatch = useDispatch();
    const [colorMode, setColorMode] = useColorMode();
    const [staticChangableData, setStaticChangebleData] = useState();
    const themeColor = localStorage.getItem("theme-ui-color-mode");

    const getData = () => {
        fetch("changables/data.json"
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then((response) => {
                // console.log(response)
                return response.json();
            })
            .then((data) => {
                // console.log(data);
                setStaticChangebleData(data);
            });
    };

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        // if (!localStorage.getItem("theme-ui-color-mode")) {
        if (staticChangableData?.app_theme) {
            dispatch(setInnerLoaderTrue());
            if (!localStorage.getItem("theme-ui-color-mode")) {
                setColorMode(staticChangableData?.app_theme);
            } else {
                setColorMode(themeColor);
            }
            dispatch(setInnerLoaderFalse());
        }
    }, [dispatch, staticChangableData?.app_theme, themeColor]);

    const changeThemeMode = useCallback(() => {
        dispatch(setInnerLoaderTrue());
        setColorMode((colorMode === "light") ? "dark" : "light");
        dispatch(setInnerLoaderFalse());

    }, [dispatch, colorMode, setColorMode]);

    return {
        colorMode,
        changeThemeMode,
    };
};