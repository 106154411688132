import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "./GameCard.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useThemeColor } from "../../../../hooks/useThemeColor";
import { rootUrl, userKey } from "../../../../App";
import { setInnerLoaderFalse, setInnerLoaderTrue } from "../../../../Redux/loaderSlice";

import Swal from "sweetalert2";
import { createPortal } from "react-dom";

import LauncherErrorAlert from "../../../../Components/Shared/CustomSweetAlert/LauncherErrorAlert";
import useGameErrors from "../../../../hooks/useGameErrors";

// import { userDetail } from "../../../../Redux/userSlice";
// import { fetchLoginUserWallet } from "../../../../Redux/userSlice";

const imageLoader = process.env.PUBLIC_URL + "/Assets/Images/Icons/SharedIcons/image-loader-2.svg";

const GameCard = ({ gameCardData }) => {
    // const user = useSelector(userDetail);
    const { colorMode } = useThemeColor();

    const [loadingImage, setLoadingImage] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { findErrorByCode, closeErrorAlert, showGameErrorsSweetAlert2, errorAlert, } = useGameErrors();

    // console.log(user?.user?.user_base_origin);

    // const convertUrl = (url) => {
    //     // Remove "https://" if present
    //     let domain = url.replace(/^https?:\/\//i, '');

    //     // Remove trailing slashes
    //     domain = domain.replace(/\/+$/, '');

    //     // Check if 'www' is already present, if not, add it
    //     if (!/^www\./i.test(domain)) {
    //         domain = 'www.' + domain;
    //     }

    //     return domain;
    // }


    const gsGameLaunchFunction = (game) => {

        // console.log(game);

        dispatch(setInnerLoaderTrue());

        let stringValue = localStorage.getItem(userKey);
        let value = JSON.parse(stringValue);

        if (!value?.value) {
            navigate("/login");
            return;
        }

        // const gameLaunchExtraparams = `&html5=1`;

        const mainDiv = document.getElementById("main");
        const getHeight = mainDiv?.scrollHeight;
        const getWidth = mainDiv?.clientWidth;

        const getInnerWidth = window.innerWidth;

        // const convertedUrl = convertUrl(user?.user?.user_base_origin);
        // console.log(convertedUrl);

        if ((game?.iframe === true) && (getInnerWidth < 769)) {
            const launchUrl = `/launch/${game?.p_code}/${game?.p_type}/${game?.g_code || 0}/${game?.operator || 0}`;
            // console.log(launchUrl);
            navigate(`${launchUrl}`);

            // const launchWindow = window.open(launchUrl, `<div id="launchWindow"></div>`, `width=${getWidth},height=${getHeight}`, "_blank");
            // window.onload = () => {
            //     launchWindow.opener.document.getElementById("launchWindow").innerHTML = `<div id=""></div>`;
            // }
            // dispatch(setInnerLoaderFalse());
            // navigate(`/launch/${game?.p_code}/${game?.p_type}/${game?.g_code}`);
            return;
        }

        let fullUrl = `${rootUrl}/game/launchGame/${game?.p_code}/${game?.p_type}/?game_id=${game?.g_code || ""}&operator=${game?.operator || ""}`;

        fetch(fullUrl, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Token ${value?.value}`,
            }
        })
            .then(res => {
                if (!res.ok) throw res;
                else return res.json();
            })
            .then(data => {
                // console.log(data);
                if (data?.status === "ok") {
                    dispatch(setInnerLoaderFalse());
                    const launchWindow = window.open(`${data?.data?.gameUrl}`, `<div id="launchWindow"></div>`, `width=${getWidth},height=${getHeight}`);
                    window.onload = () => {
                        launchWindow.opener.document.getElementById("launchWindow").innerHTML = `<div id=""></div>`;
                    }

                    //     if (data?.error) {
                    //         dispatch(showCustomAlert({ message: `${data?.error}`, type: 'error' }));
                    //     }

                    // dispatch(fetchLoginUserWallet());
                }
                if (data?.status === "failed") {
                    // setLaunchUrl(null);
                    const matchedError = findErrorByCode(data?.errCode);
                    if (matchedError) {
                        showGameErrorsSweetAlert2(matchedError, false);
                        // navigate(-1);
                    }
                    return;
                }
            })
            .catch(err => {
                err.json().then(text => {
                    // if (text?.errMsg === `Account transaction is locked`) {
                    //     dispatch(showCustomAlert({ message: `${text?.errMsg}`, type: 'error' }));
                    //     return;
                    // }
                    // if (text?.detail === "Invalid token.") {
                    //     // dispatch(fetchLogoutUser());
                    //     return;
                    // }
                });
            })
            .finally(() => {
                dispatch(setInnerLoaderFalse());
                // setPwaLoader(true);
            })
    };

    // console.log(gameCardData);
    return (
        <section className="ms-2">
            <Row xs={2} md={2} className="d-flex justify-content-start align-items-center gx-0 gy-2">
                {
                    (gameCardData?.length > 0) &&
                    gameCardData?.map((item, idx) => (
                        <Col
                            onClick={() => gsGameLaunchFunction(item)}
                            key={(item?.imgFileName + idx) || idx}
                            className="game-card d-flex justify-content-center align-items-center pointer gx-2"
                        >
                            <Card className="custom-shadow rounded w-100 p-0 m-0"
                                style={{
                                    backgroundColor: (colorMode === "light") ? "var(--white)" : "var(--blackish-ash)",
                                    color: (colorMode === "light") ? "var(--dark)" : "var(--white)",
                                }}
                            >
                                <Card.Img variant="top"
                                    src={
                                        loadingImage ? (
                                            ((item?.imgFileName)?.search(`http`) ? (rootUrl + item?.imgFileName)
                                                : (item?.imgFileName)
                                            )
                                            || imageLoader
                                        )
                                            : imageLoader
                                    }
                                    loading="lazy"
                                    className="rounded-top m-0 p-0"
                                    height="120px" width=""
                                    onLoad={() => setLoadingImage(true)}
                                // alt={item?.title}
                                />
                                <Card.Body className="px-2 py-2 text-start rounded-bottom">
                                    {
                                        item?.gameName?.gameName_enus ?
                                            <Card.Title className="game-card-title">{((item?.gameName?.gameName_enus)?.length >= 12) ? (item?.gameName?.gameName_enus)?.slice(0, 12) + `...` : (item?.gameName?.gameName_enus)}</Card.Title>
                                            :
                                            item?.title ?
                                                <Card.Title className="game-card-title">{((item?.title)?.length >= 12) ? (item?.title)?.slice(0, 12) + `...` : (item?.title)}</Card.Title>
                                                :
                                                <Card.Title className="game-card-title">{(item?.Trans?.en)?.length >= 12 ? (item?.Trans?.en)?.slice(0, 12) + `...` : (item?.Trans?.en)}</Card.Title>
                                    }
                                    {
                                        item?.MerchantName ?
                                            <Card.Text className="game-card-subtitle">
                                                {item?.MerchantName}
                                            </Card.Text>
                                            :
                                            item?.game_provider ?
                                                <Card.Text className="game-card-subtitle">
                                                    {item?.game_provider}
                                                </Card.Text>
                                                :
                                                (item?.extra_param !== "iframe") ?
                                                    <Card.Text className="game-card-subtitle">
                                                        {item?.g_type}
                                                    </Card.Text>
                                                    :
                                                    <></>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                }
            </Row>
            {
                (errorAlert?.visible)
                &&
                createPortal(
                    <LauncherErrorAlert close={closeErrorAlert} item={errorAlert?.item} />,
                    Swal.getHtmlContainer() || document.body
                )
            }
        </section >
    );
};

export default GameCard;